import { useEffect, useRef, useState, createRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import { isBrowser, isMobile, browserName, fullBrowserVersion, mobileModel, osVersion, osName } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";
import IoTClient from "../../utils/iot/IoTClient";

import {
  Box,
  Stack,
  IconButton,
  Slider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Button,
  Tooltip,
  CardMedia,
} from "@mui/material";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import TuneIcon from "@mui/icons-material/Tune";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import DoneIcon from "@mui/icons-material/Done";
import Collapse from "@mui/material/Collapse";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import { HttpUnAuthApi } from "../../interface/unauth-api";
import { HttpStatisticApi } from "../../interface/statistic-api";
import { CommonUtils } from "../../utils/common_utils";
import { useInterval } from "../../utils/UseInterval";

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

const _deviceInfo: any = {
  isBrowser: isBrowser,
  isMobile: isMobile,
  browserName: browserName,
  fullBrowserVersion: fullBrowserVersion,
  mobileModel: mobileModel,
  osName: osName,
  osVersion: osVersion,
};

const unAuthApi = new HttpUnAuthApi();
const statisticApi = new HttpStatisticApi();
const utils = new CommonUtils();

const resolutionList: any = [1080, 720, 540, 360];
const rateList: any = [2.0, 1.75, 1.5, 1.25, 1.0, 0.75, 0.5, 0.25];

const Player = () => {
  const { videoSeq } = useParams();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const iotRef: any = useRef();
  const videoRef = useRef<any>(null); // video tag
  const ivsRef: any = useRef();
  const playerRef = useRef<any>(); // player screen
  const posterRef: any = useRef();
  const videoSliderRef: any = useRef();
  const isChange = useRef(false); // playtime slider
  const startPlaying = useRef(false);
  const videoControls = createRef<HTMLDivElement>();

  const [videoDisplayTitle, setVideoDisplayTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoOriginUrl, setVideoOriginUrl] = useState("");
  const [maxResolution, setMaxResolution] = useState(1080);

  // 비디오의 실제 크기
  const [videoHeight, setVideoHeight] = useState(-1);
  const [videoWidth, setVideoWidth] = useState(-1);

  // 플레이어 스크린 동적 사이즈용
  const [maxWidth, setMaxWidth] = useState("0%");
  const [height, setHeight] = useState("0%");

  // 비디오 컨트롤
  const [play, setPlay] = useState(queryParams.noAutoPlay === undefined ? true : false);
  const [mute, setMute] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [pip, setPip] = useState(false);

  const [videoInfo, setVideoInfo] = useState<any>({});
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);
  const videoDurationRef = useRef(0);

  const [volume, setVolume] = useState<number>(isMobile ? 1 : 0);
  const [lastVolume, setLastVolume] = useState<number>(1);

  const [viewControl, setViewControl] = useState(false); // 마우스 오버시 컨트롤 표시
  const [viewVolumeControl, setViewVolumeControl] = useState(false); // 마우스 오버시 컨트롤 표시

  const [playResolution, setPlayResolution] = useState(0); // 현재 재생중인 화질
  const [playRate, setPlayRate] = useState(1.0); // 현재 재생중인 속도

  const [totalLike, setTotalLike] = useState(0);

  const videoStatusRef = useRef("Idle");

  // 플레이어 더보기(화질 및 재생속도) 팝업 메뉴용
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // 팝메뉴 > 확장 메뉴 관리 용
  const [deepMenuFlag, setDeepMenuFlag] = useState("");

  const [sliderThumbLeft, setSliderThumbLeft] = useState("0%");
  const [sliderThumbImgRoot, setSliderThumbImgRoot] = useState("");
  const [sliderThumbImgUrl, setSliderThumbImgUrl] = useState("");
  const [sliderThumbImgUrls, setSliderThumbImgUrls] = useState<any>([]);
  const [sliderThumbImgTime, setSliderThumbImgTime] = useState("");
  const [sliderThumbImgWidth, setSliderThumbImgWidth] = useState(0);

  const onMessageArrive = (e: MessageEvent) => {
    if (e.data.msg !== undefined) {
      if (e.data.msg === "play" || e.data.msg === "stop") {
        if (e.data.videoKey !== undefined && e.data.videoKey === videoSeq) {
          if (e.data.msg === "play") {
            ivsRef.current?.play();
            setPlay(true);
          } else {
            ivsRef.current?.pause();
            setPlay(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", onMessageArrive);
    return () => {
      setVideoInfo({});
      setVideoDisplayTitle("");
      setVideoUrl("");
      setVideoOriginUrl("");
      setVideoHeight(0);
      setVideoWidth(0);
      setMaxWidth("0%");
      setHeight("0%");
      setPlay(true);
      setMute(true);
      setFullScreen(false);
      setPip(false);
      setVideoCurrentTime(0);
      setVolume(1);
      setLastVolume(1);
      setViewControl(true);
      setViewVolumeControl(false);
      setPlayResolution(0);
      setPlayRate(1.0);
      setAnchorEl(null);
      setDeepMenuFlag("");
      setTotalLike(0);
      setSliderThumbLeft("0%");
      setSliderThumbImgRoot("");
      setSliderThumbImgUrl("");
      setSliderThumbImgUrls([]);
      setSliderThumbImgTime("");
      setSliderThumbImgWidth(0);
      window.removeEventListener("message", onMessageArrive);
    };
  }, []);

  // 슬라이더 썸네일 이미지 경로 Root 계산
  const set_slider_thumbnail_root_path = (info: any) => {
    let rootPath = "";
    const playback = info.playback_url;
    let temp = playback.split("HLS");
    rootPath = temp[0] + "VideoClip";
    const temp2 = temp[1].split(".m3u8")[0];
    rootPath = rootPath + temp2;
    setSliderThumbImgRoot(rootPath);

    return rootPath;
  };

  // 슬라이더 썸네일 이미지 프리 로딩
  const set_slider_thumb_image_loading = (_duration: number, _fileRoot: string) => {
    let urls: any = [];
    for (let i = 0; i < _duration; i++) {
      const time = Math.floor(i).toString().padStart(7, "0");
      const url = `${_fileRoot}.${time}.jpg`;

      urls = [...urls, url];
    }
    setSliderThumbImgUrls(urls);
  };

  // 비디오 정보 조회
  const get_video_info = useCallback(async () => {
    if (videoSeq === undefined || videoSeq === "") return;
    const param: any = {
      command: "get_video_info",
      video_key: videoSeq,
    };

    const res = await unAuthApi.post(param);
    if (res.code === "200") {
      const video_info = res.response.video_info;
      if (video_info.display_title !== undefined) {
        document.title = video_info.display_title;
      } else {
        document.title = video_info.title;
      }
      setVideoInfo(video_info);
      setVideoDisplayTitle(video_info.display_title !== undefined ? video_info.display_title : "");
      setVideoUrl(video_info.playback_url);
      setVideoOriginUrl(video_info.origin_file_url);
      setMaxResolution(video_info.resolution !== undefined ? video_info.resolution : 1080);
      // video duration
      if (video_info.video_duration !== undefined) {
        if (typeof video_info.video_duration === "string") {
          videoDurationRef.current = parseFloat(video_info.video_duration);
        } else {
          videoDurationRef.current = video_info.video_duration;
        }
      }
      // 썸네일 처리
      const video_default_thumb = video_info.video_thumb === undefined ? "video" : video_info.video_thumb;
      const tumbnal = video_info.thumbnail_url === undefined ? "" : video_info.thumbnail_url;
      const custom_tumbnal = video_info.custom_tumbnail === undefined ? "" : video_info.custom_tumbnail;

      if (tumbnal !== "" || custom_tumbnal !== "") {
        if (video_default_thumb === "video") {
          posterRef.current = tumbnal;
        } else {
          posterRef.current = custom_tumbnal;
        }
      }

      const rootPath = set_slider_thumbnail_root_path(video_info);
      set_slider_thumb_image_loading(videoDurationRef.current, rootPath);
    }
  }, [videoSeq]);

  useEffect(() => {
    get_video_info();
  }, [get_video_info]);

  // HLS 세팅
  const mediaPlayerScriptLoaded = (_PLAYBACK_URL: string) => {
    const MediaPlayerPackage = window.IVSPlayer;
    if (!MediaPlayerPackage.isPlayerSupported) {
      console.warn("지원하지 않는 브라우저입니다. 다른 브라우저로 시도하세요.");
    }

    const PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    ivsRef.current = MediaPlayerPackage.create({
      serviceWorker: {
        url: "amazon-ivs-service-worker-loader.js",
      },
    });
    ivsRef.current.attachHTMLVideoElement(document.getElementById("videoPlayer-" + videoSeq));
    ivsRef.current.addEventListener(PlayerState.IDLE, onIdle);
    ivsRef.current.addEventListener(PlayerState.READY, onReady);
    ivsRef.current.addEventListener(PlayerState.PLAYING, onStatePlaying);
    ivsRef.current.addEventListener(PlayerState.ENDED, onStateEnd);
    ivsRef.current.addEventListener(PlayerEventType.ERROR, onError);
    ivsRef.current.addEventListener(PlayerEventType.PLAYBACK_RATE_CHANGED, onPlaybackRateChanged);
    ivsRef.current.addEventListener(PlayerEventType.QUALITY_CHANGED, onQualityChanged);

    // Setup stream and play
    ivsRef.current.setAutoQualityMode(true);
    ivsRef.current.setMuted(true);
    ivsRef.current.setVolume(1.0);
    ivsRef.current.load(_PLAYBACK_URL);

    //if client platform is iOS
    if (_deviceInfo.osName === "iOS") {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden" && ivsRef.current.isMuted()) {
          ivsRef.current.pause();
        }
        if (document.visibilityState === "visible" && ivsRef.current.getState() !== PlayerState.PLAYING) {
          ivsRef.current.play();
        }
      });
    }

    window.addEventListener("focus", fncAutoPlay);
    function fncAutoPlay() {
      if (_deviceInfo.osName === "iOS" && _PLAYBACK_URL !== "") {
        ivsRef.current.setAutoplay(true);
        ivsRef.current.load(_PLAYBACK_URL);
        ivsRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (videoUrl !== undefined && videoUrl !== "") {
      mediaPlayerScriptLoaded(videoUrl);
    } else if (videoOriginUrl !== "" && videoOriginUrl !== undefined) {
      videoRef.current.src = videoOriginUrl;
      if (queryParams.noAutoPlay === undefined) videoRef.current.play();
    }

    if (queryParams.loop !== undefined) videoRef.current.loop = true;
  }, [videoUrl, videoOriginUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const onIdle = (cue: any) => {};
  const onReady = (cue: any) => {
    window.parent.postMessage({ msg: "loadCompelete", videoKey: videoSeq}, "*");
    videoStatusRef.current = ivsRef.current.getState();
  };

  const onStatePlaying = (cue: any) => {
    videoStatusRef.current = ivsRef.current.getState();
  };

  const onStateEnd = (cue: any) => {
    videoStatusRef.current = ivsRef.current.getState();
  };

  const onError = async (err: any) => {
    console.warn("Player Event - ERROR:", err);
  };

  const onPlaybackRateChanged = (evt: any) => {
    console.log("onPlaybackRateChanged");
  };
  const onQualityChanged = (evt: any) => {
    console.log("onQualityChanged : ", evt);
  };

  const setResolution = () => {
    // 화면 비율 계산해서 동영상 브라우저에 맞추기
    if (videoRef.current?.videoHeight) setVideoHeight(videoRef.current?.videoHeight);
    if (videoRef.current?.videoWidth) setVideoWidth(videoRef.current?.videoWidth);

    if (videoRef.current?.videoHeight === 0) {
      setMaxWidth("100%");
      setHeight("100%");
    }

    // 비디오 총시간(videoInfo에 video_druation이 없을 때만 동작)
    if (videoDurationRef.current === undefined) {
      videoDurationRef.current = videoRef.current?.duration;
    }

    // 비디오 볼륨
    videoRef.current.volume = 1.0;
    const videoWorks = !!document.createElement("video").canPlayType;
    if (videoWorks) {
      videoRef.current.controls = false;
      videoControls.current?.classList.remove("hidden");
    }
  };

  // 탐색 슬라이더 현재 플레이 타임 표시
  const setPlayTime = () => {
    if (videoRef.current && !isChange.current && videoStatusRef.current !== "Ended") {
      setVideoCurrentTime((videoCurrentTime) => videoRef.current.currentTime);
    }
  };

  // 현재 재생시간 포멧
  const valueLabelFormat = (value: number) => {
    const t = utils.convertSecToTime(value);
    if (videoDurationRef.current > 60 * 60) return t.hours + ":" + t.minutes + ":" + t.seconds;
    else return t.minutes + ":" + t.seconds;
  };

  // 비디오 이벤트 리스너
  useEffect(() => {
    const videoCurrent: any = videoRef.current;
    videoCurrent?.addEventListener("loadedmetadata", setResolution);
    videoCurrent?.addEventListener("timeupdate", setPlayTime);

    return () => {
      videoCurrent?.removeEventListener("loadedmetadata", setResolution);
      videoCurrent?.removeEventListener("timeupdate", setPlayTime);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 레이아웃 실제 비디오 크기에 맞춰서 플레이어 크기 동적 할당
  useEffect(() => {
    if (videoWidth > 0 && videoHeight > 0) {
      setMaxWidth(`calc(${(videoWidth / videoHeight) * 100}vh) !important`);
      setHeight(`calc(${(videoHeight / videoWidth) * 100}vw) !important`);
    }
  }, [videoWidth, videoHeight]);

  // 플레이어 기능 (플레이/일시정지 버튼)
  const togglePlay = () => {
    if (videoUrl !== undefined && videoUrl !== "") {
      if (ivsRef.current.isPaused()) {
        ivsRef.current.play();
        setPlay(true);
      } else {
        ivsRef.current.pause();
        setPlay(false);
      }
    } else {
      if (videoRef.current?.paused) {
        videoRef.current?.play();
        setPlay(true);
      } else {
        videoRef.current?.pause();
        setPlay(false);
      }
    }
  };

  // 플레이어 기능 (음소거)
  const toggleMute = () => {
    if (videoRef.current?.muted) {
      videoRef.current.muted = false;
      setMute(false);
      if (isMobile) videoRef.current.volume = 1.0;
      setVolume(lastVolume);
    } else {
      videoRef.current.muted = true;
      setMute(true);
      setVolume(0);
    }
  };

  // 슬라이더 Thumb 위치에 따라서 썸네일 이미지 div 위치 계산
  const calcSliderThumbImgPos = (pos: number) => {
    const dur = videoDurationRef.current;
    const div = 100 * (pos / dur);
    const imageWidth = (160 / parseFloat(videoInfo.video_rate.split("%")[0])) * 100;
    setSliderThumbImgWidth(imageWidth); // 슬라이더 이미지 넓이 계산

    let leftPos = "";
    if (div < 50) {
      leftPos = `max(0px, calc(${div}% - ${imageWidth / 2}px))`;
    } else {
      leftPos = `min(calc(100% - ${imageWidth}px), calc(${div}% - ${imageWidth / 2}px))`;
    }
    setSliderThumbLeft((sliderThumbLeft) => leftPos);
  };

  // 슬라이더 재생시간, 해당 시간의 이미지 경로 설정
  const set_slider_thumb_image_layer = (sec: number) => {
    const time = Math.floor(sec).toString().padStart(7, "0");
    setSliderThumbImgTime(valueLabelFormat(sec));
    const url = `${sliderThumbImgRoot}.${time}.jpg`;
    setSliderThumbImgUrl(url);
  };

  // 비디오 탐색 슬라이더 변경시 이벤트
  const handleChange = (event: Event, newValue: number | number[]) => {
    isChange.current = true;
    set_slider_thumb_image_layer(newValue as number);
    calcSliderThumbImgPos(newValue as number);
    setVideoCurrentTime((videoCurrentTime) => newValue as number);
  };

  // 비디오 탐색 슬라이더 변경 완료시 이벤트
  const completeChange = (event: any, newValue: number | number[]) => {
    videoRef.current.currentTime = newValue as number;
    isChange.current = false;
  };

  // 볼륨 레이블
  const labelVolumFormat = (value: number) => {
    return value;
  };

  // 볼륨 컨트롤 변경시 이벤트
  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    setVolume(newValue as number);
    setLastVolume(newValue as number);
    videoRef.current.volume = newValue as number;
    if (newValue === 0) {
      setMute(true);
      videoRef.current.muted = true;
    } else {
      setMute(false);
      videoRef.current.muted = false;
    }
  };

  // 풀스크린
  const toggleFullscreen = () => {
    if (fullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      if (playerRef.current.requestFullScreen) {
        playerRef.current.requestFullScreen();
      } else if (playerRef.current.webkitRequestFullScreen) {
        playerRef.current.webkitRequestFullScreen();
      } else if (playerRef.current.mozRequestFullScreen) {
        playerRef.current.mozRequestFullScreen();
      }
    }
    setFullScreen(!fullScreen);
  };

  // 팝메뉴 열기
  const handlePopMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // 팝메뉴 닫기
  const closePopMenu = () => {
    setAnchorEl(null);
    deepMenu("");
  };

  // 현재 확장 메뉴
  const deepMenu = (flag: string) => {
    setDeepMenuFlag(flag);
  };

  // 마우스 위치에 따른 비디오 컨트롤 제어
  const handleViewControl = (flag: boolean) => {
    if (!flag) closePopMenu();
    setViewControl(flag);
  };

  // 화질 변경
  const handleResolution = (resolution: number) => {
    closePopMenu(); // 팝메뉴 닫기
    ivsRef.current.pause(); // 현재 구간에서 일시정지
    const nowTime = videoRef.current.currentTime; // 현재의 플레이 타임
    if (resolution > 0) {
      // 화질을 직접 선택할 경우
      const newPlaybackUrls = videoUrl.split(".m3u8");
      const newPlayBackUrl = newPlaybackUrls[0] + "_" + resolution + ".m3u8";
      ivsRef.current.load(newPlayBackUrl);
    } else {
      // 화질을 auto로 설정할 경우
      ivsRef.current.load(videoUrl);
    }
    videoRef.current.currentTime = nowTime; // 새로 변경된 playback을 직전의 플레이 시간으로 변경
    ivsRef.current.play(); // 비디오 재생시작
    setPlayResolution(resolution); // 현재 선택한 화질
  };

  // 비디오 재생 속도 변경
  const handleVideoRate = (rate: number) => {
    closePopMenu();
    videoRef.current.playbackRate = rate;
    setPlayRate(rate);
  };

  function dataURItoBlob(dataURI: any) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  }

  const capture = async () => {
    let video: any = document.getElementById("videoPlayer-" + videoSeq);
    let oCanvas: any = document.createElement("canvas");
    oCanvas.width = video.videoWidth;
    oCanvas.height = video.videoHeight;
    await oCanvas.getContext("2d").drawImage(video, 0, 0, oCanvas.width, oCanvas.height);

    const dataUrl = oCanvas.toDataURL("image/jpeg");
    const blobData = dataURItoBlob(dataUrl);
    const fileName = `snapshot/${videoSeq}/${uuidv4()}-snapshot.jpg`;

    // 파일 업로드
    const param: any = {
      command: "get_cf_presigned_url",
      key: fileName,
    };

    const res = await unAuthApi.post(param);
    if (res.code === "200") {
      const upload_url = res.response.upload_url;
      const uploadRes = await fetch(upload_url, {
        method: "PUT",
        headers: { "Content-Type": "image/jpeg", acl: "public-read" },
        body: blobData,
      });
      if (uploadRes.status === 200) {
        window.parent.postMessage({ msg: "upload_custom_thumb", file_name: fileName }, "*");
      }
    }
  };

  useEffect(() => {
    if (videoStatusRef.current === "Ready") {
      setTimeout(() => {
        if (queryParams.noAutoPlay === undefined) ivsRef.current.play();
      }, 300);
    } else if (videoStatusRef.current === "Playing") {
      startPlaying.current = true;
    } else if (startPlaying.current && videoStatusRef.current === "Ended") {
      setVideoCurrentTime(videoDurationRef.current);
      setPlay(false);
    }
  }, [videoStatusRef, queryParams.noAutoPlay]);

  const get_statistic_info = async () => {
    const param: any = {
      command: "get_statistic_info",
      video_key: videoSeq,
    };

    const res = await statisticApi.post(param);
    if (res.code === "200") {
      if (res.response.statistic_info?.total_likes !== undefined) setTotalLike(res.response.statistic_info.total_likes);
    }
  };

  // view count
  const increaseStatistic = async (prot: string) => {
    const param: any = {
      command: "update_count",
      video_key: videoSeq,
      prot: prot,
    };

    await statisticApi.post(param);
  };

  // watch time count
  const increaseWatchTime = async () => {
    const param: any = {
      command: "update_watch_statistics",
      video_key: videoSeq
    };

    await statisticApi.post(param);
  }

  useEffect(() => {
    get_statistic_info();
    increaseStatistic("view");
    increaseWatchTime();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      increaseWatchTime();
    },
    videoSeq !== "" && play ? delay : null
  );

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payload: any, clientId: string) {
    if (topic === `video/${videoSeq}/statistic`) {
      if (payload.prot === "like") setTotalLike((totalLike) => totalLike + 1);
    }
  }

  return (
    <>
      <div ref={playerRef} className="viewer-root">
        <div className={"Live-Layout-wrapper small-layout"}>
          <Box className={"Live-Layout-inner small-layout-inner"} sx={{ maxWidth: maxWidth, height: height }}>
            <div
              className="Live-Swipe-content"
              onMouseEnter={(e) => {
                if (e.target === document.getElementsByClassName("client-video")[0]) handleViewControl(true);
              }}
              onMouseLeave={(e) => {
                if (e.target === document.getElementsByClassName("client-video")[0]) handleViewControl(false);
              }}
              onClick={(e: any) => {
                if (e.target === document.getElementsByClassName("client-video")[0]) handleViewControl(!viewControl);
              }}
            >
              <div className="VideoPlayer-wrapper color-snow">
                <Box sx={{ zIndex: 1 }}>
                  {/* 비디오 */}
                  {queryParams.noAutoPlay === undefined ? (
                    <video
                      ref={videoRef}
                      className={"client-video"}
                      id={"videoPlayer-" + videoSeq}
                      poster={posterRef.current}
                      playsInline
                      muted
                      controls
                      autoPlay
                    ></video>
                  ) : (
                    <video
                      ref={videoRef}
                      className={"client-video"}
                      id={"videoPlayer-" + videoSeq}
                      poster={posterRef.current}
                      playsInline
                      muted
                      controls
                    ></video>
                  )}
                </Box>

                {/* 타이틀 영역 */}
                {viewControl && queryParams.noTitle === undefined && (
                  <>
                    <div className="player-title">
                      <Box sx={{ p: "5px 10px 5px 10px", backgroundColor: "#00000070", borderRadius: "7px" }}>
                        <Typography sx={{ fontWeight: "700 !important" }}>{videoDisplayTitle}</Typography>
                      </Box>
                    </div>
                    {queryParams.thumb !== undefined && (
                      <div className="player-thumbnail-btn">
                        <Button
                          variant="contained"
                          onClick={() => {
                            capture();
                          }}
                        >
                          Set Thumbnail
                        </Button>
                      </div>
                    )}
                  </>
                )}
                {/* 좋아요 버튼 */}
                {viewControl && queryParams.noLike === undefined && (
                  <Stack direction={"column"} spacing={1} sx={{ position: "absolute", top: "0.8rem", right: "0.8rem", zIndex: "802" }}>
                    <Box
                      sx={{
                        backgroundColor: "#00000070",
                        borderRadius: "7px",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          increaseStatistic("like");
                        }}
                      >
                        <FavoriteBorderIcon sx={{ color: "#fff" }} />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "#00000070",
                        borderRadius: "7px",
                      }}
                    >
                      <Typography sx={{ display: "flex", justifyContent: "center", color: "#fff" }}>{totalLike}</Typography>
                    </Box>
                  </Stack>
                )}

                {/* 컨트롤러 */}
                {videoDurationRef.current > 0 && viewControl && queryParams.noControl === undefined && (
                  <div className="video-controls" id="video-controls" ref={videoControls}>
                    <Stack direction="row" spacing={2} sx={{ position: "relative" }}>
                      <Box id="enc" sx={{ position: "absolute", bottom: "40px", right: "-4px" }}></Box>
                      {/* 플레이버튼 */}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={play ? "일시정지" : "재생"}>
                          <IconButton sx={{ padding: 0, display: "flex" }} onClick={togglePlay}>
                            {play && videoStatusRef.current !== "Ended" ? (
                              <PauseIcon className="color-snow" />
                            ) : (
                              <PlayArrowIcon className="color-snow" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Box>
                      {/* 슬라이더 */}
                      <Box sx={{ width: "100%", position: "relative" }}>
                        <Slider
                          aria-label="video"
                          ref={videoSliderRef}
                          value={videoCurrentTime}
                          min={0}
                          max={videoDurationRef.current}
                          step={0.001}
                          onChange={handleChange}
                          onChangeCommitted={completeChange}
                          color="error"
                          sx={{
                            "& .MuiSlider-thumb": { width: 2, height: 2, boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)" },
                            "& .MuiSlider-track": { transition: "width 0.21s linear" },
                            "& .MuiSlider-rail": {
                              opacity: 0.5,
                              boxShadow: "inset 0px 0px 4px -2px #000",
                              backgroundColor: "#dod0d0",
                            },
                          }}
                        />
                        {isChange.current && (
                          <Box sx={{ position: "absolute", left: sliderThumbLeft, bottom: "45px", maxLeft: "0%" }}>
                            <Box sx={{ position: "relative", maxWidth: `${sliderThumbImgWidth}px` }}>
                              <CardMedia
                                component="img"
                                image={sliderThumbImgUrl}
                                sx={{
                                  objectFit: "contain",
                                  maxWidth: `${sliderThumbImgWidth}px`,
                                  maxHeight: "160px",
                                  borderRadius: "7px",
                                }}
                              />
                              <Box
                                sx={{ position: "absolute", left: "50%", bottom: "4px", transform: "translate(-50%, 0)", zIndex: "901" }}
                              >
                                <Typography sx={{ color: "#ffffff" }}>{sliderThumbImgTime}</Typography>
                              </Box>
                              <Box
                                sx={{
                                  position: "absolute",
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  top: 0,
                                  borderRadius: "7px",
                                  backgroundColor: "#100f0f",
                                  opacity: "0.2",
                                  zIndex: "900",
                                }}
                              ></Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      {/* 기타 컨트롤 */}
                      <Stack direction="row" spacing={1}>
                        {/* 볼륨 컨트롤 */}
                        {!isMobile ? (
                          <Box
                            sx={{
                              position: "relative",
                              width: "24px",
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={
                                viewVolumeControl
                                  ? { position: "absolute", left: 0, top: "-127px", display: "flex", flexDirection: "column" }
                                  : { position: "absolute", left: 0, buttom: 0, display: "flex", flexDirection: "column" }
                              }
                              onMouseEnter={() => {
                                setViewVolumeControl(true);
                              }}
                              onMouseLeave={() => {
                                setViewVolumeControl(false);
                              }}
                            >
                              {viewVolumeControl && (
                                <Box
                                  sx={{
                                    height: "100px",
                                    padding: "10px 0px 10px 0px",
                                    backgroundColor: "#00000070",
                                    borderRadius: "7px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Slider
                                    orientation="vertical"
                                    valueLabelDisplay="off"
                                    value={volume}
                                    min={0}
                                    max={1.0}
                                    step={0.01}
                                    valueLabelFormat={labelVolumFormat}
                                    onChange={handleVolumeChange}
                                    color="error"
                                    sx={{
                                      p: "0 10px",
                                      "& .MuiSlider-thumb": { display: "none" },
                                    }}
                                  />
                                </Box>
                              )}
                              <IconButton sx={{ padding: 0 }} onClick={toggleMute}>
                                {mute ? <VolumeOffIcon className="color-snow" /> : <VolumeUpIcon className="color-snow" />}
                              </IconButton>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
                            <IconButton sx={{ padding: 0 }} onClick={toggleMute}>
                              {mute ? <VolumeOffIcon className="color-snow" /> : <VolumeUpIcon className="color-snow" />}
                            </IconButton>
                          </Box>
                        )}
                        {/* Setting */}
                        {videoUrl !== undefined && videoUrl !== "" && (
                          <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
                            <Tooltip title="기타">
                              <IconButton sx={{ padding: 0, display: "flex" }} onClick={handlePopMenu}>
                                <SettingsIcon className="color-snow" />
                              </IconButton>
                            </Tooltip>
                            {/* 기본 메뉴 */}
                            {deepMenuFlag === "" && (
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={document.getElementById("enc")}
                                open={open}
                                onClose={closePopMenu}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                slotProps={{
                                  paper: {
                                    style: {
                                      backgroundColor: "#00000095",
                                      width: 280,
                                      maxWidth: "100%",
                                    },
                                  },
                                }}
                                transitionDuration={2}
                              >
                                <MenuItem
                                  onClick={() => {
                                    deepMenu("resolution");
                                  }}
                                >
                                  <ListItemIcon>
                                    <TuneIcon className="color-snow" />
                                  </ListItemIcon>
                                  <ListItemText className="color-snow">화질</ListItemText>
                                  <Typography variant="body2" className="color-snow">
                                    {playResolution === 0 ? "AUTO" : `${playResolution}p`}
                                  </Typography>
                                  <NavigateNextIcon className="color-snow" />
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    deepMenu("rate");
                                  }}
                                >
                                  <ListItemIcon>
                                    <SlowMotionVideoIcon className="color-snow" />
                                  </ListItemIcon>
                                  <ListItemText className="color-snow">재생속도</ListItemText>
                                  <Typography variant="body2" className="color-snow">
                                    {playRate === 1.0 ? "보통" : `${playRate}`}
                                  </Typography>
                                  <NavigateNextIcon className="color-snow" />
                                </MenuItem>
                              </Menu>
                            )}
                            {/* 화질 선택 */}
                            {deepMenuFlag === "resolution" && (
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={document.getElementById("enc")}
                                open={open}
                                onClose={closePopMenu}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                slotProps={{
                                  paper: {
                                    style: {
                                      backgroundColor: "#00000095",
                                      width: 280,
                                      maxWidth: "100%",
                                    },
                                  },
                                }}
                                transitionDuration={4}
                                TransitionComponent={Collapse}
                              >
                                <MenuItem
                                  onClick={() => {
                                    deepMenu("");
                                  }}
                                >
                                  <ListItemIcon>
                                    <NavigateBeforeIcon className="color-snow" />
                                  </ListItemIcon>
                                  <ListItemText className="color-snow">화질</ListItemText>
                                </MenuItem>
                                <Divider sx={{ bgcolor: "snow" }} />
                                {resolutionList.map(
                                  (resolution: number, index: number) =>
                                    resolution <= maxResolution && (
                                      <MenuItem
                                        key={`resol-${index}`}
                                        onClick={() => {
                                          handleResolution(resolution);
                                        }}
                                        sx={{ p: 2 }}
                                      >
                                        <ListItemIcon>{playResolution === resolution && <DoneIcon className="color-snow" />}</ListItemIcon>
                                        <ListItemText className="color-snow">{resolution}p</ListItemText>
                                      </MenuItem>
                                    )
                                )}
                                <MenuItem
                                  onClick={() => {
                                    handleResolution(0);
                                  }}
                                  sx={{ p: 2 }}
                                >
                                  <ListItemIcon>{playResolution === 0 && <DoneIcon className="color-snow" />}</ListItemIcon>
                                  <ListItemText className="color-snow">AUTO</ListItemText>
                                </MenuItem>
                              </Menu>
                            )}
                            {/* 재생속도 선택 */}
                            {deepMenuFlag === "rate" && (
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={document.getElementById("enc")}
                                open={open}
                                onClose={closePopMenu}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                slotProps={{
                                  paper: {
                                    style: {
                                      backgroundColor: "#00000095",
                                      width: 280,
                                      maxWidth: "100%",
                                    },
                                  },
                                }}
                                transitionDuration={4}
                                TransitionComponent={Collapse}
                              >
                                <MenuItem
                                  onClick={() => {
                                    deepMenu("");
                                  }}
                                >
                                  <ListItemIcon>
                                    <NavigateBeforeIcon className="color-snow" />
                                  </ListItemIcon>
                                  <ListItemText className="color-snow">재생속도</ListItemText>
                                </MenuItem>
                                <Divider sx={{ bgcolor: "snow" }} />
                                {rateList.map((rate: number, index: number) => (
                                  <MenuItem
                                    key={`rate-${index}`}
                                    onClick={() => {
                                      handleVideoRate(rate);
                                    }}
                                    sx={{ p: 2 }}
                                  >
                                    <ListItemIcon>{playRate === rate && <DoneIcon className="color-snow" />}</ListItemIcon>
                                    <ListItemText className="color-snow">{rate === 1.0 ? "보통" : rate}</ListItemText>
                                  </MenuItem>
                                ))}
                              </Menu>
                            )}
                          </Box>
                        )}

                        {/* PIP */}
                        <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
                          <Tooltip title="Picture in picture">
                            <IconButton
                              sx={{ padding: 0, display: "flex" }}
                              onClick={() => {
                                if (pip) {
                                  if (document.exitPictureInPicture) document.exitPictureInPicture();
                                } else {
                                  if (videoRef.current.requestPictureInPicture) videoRef.current.requestPictureInPicture();
                                }
                                setPip(!pip);
                              }}
                            >
                              {pip ? (
                                <PictureInPictureIcon className="color-snow" sx={{ transform: "scaleX(-1)" }} />
                              ) : (
                                <PictureInPictureAltIcon className="color-snow" />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Box>
                        {/* Full screen */}
                        <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
                          <Tooltip title="전체화면">
                            <IconButton sx={{ padding: 0, display: "flex" }} onClick={toggleFullscreen}>
                              {fullScreen ? <FullscreenExitIcon className="color-snow" /> : <FullscreenIcon className="color-snow" />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>
                    </Stack>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>
      {videoSeq !== undefined &&
        process.env.REACT_APP_IOT_REGION !== undefined &&
        process.env.REACT_APP_IOT_IDENTITY_POOL_ID !== undefined &&
        process.env.REACT_APP_IOT_ENDPOINT !== undefined && (
          <IoTClient
            ref={iotRef}
            videoSeq={videoSeq}
            iotRegion={process.env.REACT_APP_IOT_REGION}
            iotIdentityPollId={process.env.REACT_APP_IOT_IDENTITY_POOL_ID}
            iotEndPoint={process.env.REACT_APP_IOT_ENDPOINT}
            recieveIoTMessage={recieveIoTMessage}
            iotConnectEnd={() => {
              console.info("iot Connection Success");
            }}
            iotLost={() => {
              console.error("iot Lost >>> Reload");
            }}
          />
        )}
      {sliderThumbImgUrls.map((url: string, index: number) => (
        <CardMedia key={`temp-${index}`} component="img" image={url} sx={{ height: 0, width: 0 }} />
      ))}
    </>
  );
};

export default Player;
